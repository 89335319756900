<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Новые товары
        </h4>
      </div>
      <div class="mb-1">
        <a href="" @click.prevent="selectAll">Выбрать всё</a>
        <a href="" class="ml-1" @click.prevent="resetSelection">Убрать выделение</a>
        <b-button
            v-if="selectedItemList.length > 0"
            variant="primary"
            class="btn-icon ml-1"
            @click="saveAllProducts"
        >
          Добавить выделенные
        </b-button>
        <b-button
            v-if="selectedItemList.length > 0"
            variant="primary"
            class="btn-icon ml-1"
            @click="deleteAllProducts"
        >
          Удалить выделенные
        </b-button>
      </div>
      <vue-good-table
          :columns="columns"
          :rows="newProducts"
      >
        <!-- :class="{ 'newProduct':  moment().diff(moment(props.row.creaated_at), 'days') <= 1}" -->
        <template
            slot="table-row"
            slot-scope="props"
            class="newProduct"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                    variant="outline-primary"
                    @click="saveNewProductInShop(props.row.id)"
                >
                  <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                  />
                  <span>Добавить</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-b-modal.modal-primary
                    variant="outline-primary"
                    @click="openModal(props.row.id)"
                >
                  <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'select'">
            <b-form-checkbox
                v-model="selectedItemList"
                :value="props.row.id"
                class="mr-0"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <div>
      <!-- button trigger -->
      <div class="demo-inline-spacing"/>
      <!-- modal -->
      <b-modal
          id="modal-primary"
          v-model="modalIsOpen"
          ok-only
          ok-title="Отмена"

          modal-class="modal-primary"
          centered
          title="Удаление товара"
      >
        <div>Вы действительно хотите удалить товар?</div>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-1"
            variant="primary"
            @click="deleteProduct()"
        >
          Удалить
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'NewProduct',
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
  },

  data() {
    return {
      selectedItemList: [],
      columns: [
        {
          field: 'select',
        },
        {
          field: 'name',
          label: 'Название',
        },
        {
          field: 'barcode128',
          label: 'Код',
        },
        {
          field: 'sale_price',
          label: 'Цена',
        },
        {
          field: 'action',
          label: 'Действие',
        },
      ],
      modalIsOpen: false,
      currentProductId: {}
    }
  },
  computed: {
    newProducts() {
      return this.$store.state.storage.newProducts
    },
  },
  methods: {
    async saveAllProducts() {
      for (const productId of this.selectedItemList) {
        await this.saveNewProductInShop(productId)
      }
      this.selectedItemList = []
    },
    async deleteAllProducts() {
      for (const productId of this.selectedItemList) {
        this.currentProductId = productId
        await this.deleteProduct()
      }
      this.selectedItemList = []
    },
    resetSelection() {
      this.selectedItemList = []
    },
    selectAll() {
      this.selectedItemList = this.newProducts.map(product => product.id)
    },
    deleteProduct() {
      this.$store.dispatch('storage/removeNewProduct', this.currentProductId)
      const index = this.newProducts.findIndex(el => el.id === this.currentProductId)
      this.newProducts.splice(index, 1)
      this.modalIsOpen = false;
    },
    async saveNewProductInShop(id) {
      await this.$store.dispatch('storage/addProductInShop', id)
      const index = this.newProducts.findIndex(el => el.id === id)
      this.newProducts.splice(index, 1)
    },
    openModal(data) {
      this.currentProductId = data;
      this.modalIsOpen = true;
    }
  },
  created() {
    this.$store.dispatch('storage/getNewProducts');
  }
}
</script>
<style lang="scss">
.card {
  padding: 20px;

  .card-header {
    padding: 20px 0;
  }

  .newProduct {
    border: 2px color greenyellow;
  }
}

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
